module.exports = [{
      plugin: require('/Users/artfh/Projects/bottlebird/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/artfh/Projects/bottlebird/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-126704723-2"},
    },{
      plugin: require('/Users/artfh/Projects/bottlebird/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
